<template>
  <input
    :class="className"
    :disabled="disabled"
    v-bind:value="value"
    @focus="$event.target.select()"
    class="mark_entry_input"
    type="text"
    @keyup="keyPressed"
  />
</template>
<script>
let doneTypingInterval = 200;
let typingTimer;

export default {
  props: {
    disabled: { type: Boolean },
    className: { type: String },
    value: {},
  },
  data: () => {
    return {
      //   value: "",
    };
  },
  methods: {
    keyPressed(e) {
      // clearTimeout(typingTimer);
      this.$emit("input", e.target.value);
      this.$emit("afterChange", e);

      // typingTimer = setTimeout(() => {}, doneTypingInterval);
    },
  },
};
</script>
